import { render, staticRenderFns } from "./DetailChildren.vue?vue&type=template&id=009c9f9a&scoped=true&"
import script from "./DetailChildren.vue?vue&type=script&lang=js&"
export * from "./DetailChildren.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "009c9f9a",
  null
  
)

export default component.exports