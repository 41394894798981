<template>
  <div class="mt-4">

    <div v-if="method ==='edit'">
      <sgv-input-text v-model="search" label="ค้นหา"></sgv-input-text>

      <button class="btn btn-success mb-2" @click="createAll">เพิ่มทั้งหมด</button>

      <ul>
        <li v-for="item in items" :key="item.id">
          <fa
            icon="check"
            class="text-success pointer"
            @click="addPaper(item)">
          </fa>&nbsp;
          {{item.code}} <span v-show="item.name">({{item.name}})</span>
        </li>
      </ul>
    </div>

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="text-center">วันที่</th>
            <th scope="col" class="text-center">เลขที่</th>
            <th scope="col" class="text-center">หัวข้อ</th>
            <th scope="col" class="text-center">กำหนดแล้วเสร็จ</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(child,chIdx) in formData.children"
            :key="child.id">
            <td
              class=""
              style="white-space: nowrap;">
              <fa
                v-if="method === 'edit'"
                icon="trash"
                class="text-danger pointer mr-2"
                @dblclick="deletePaper(child.id)">
              </fa>
              {{chIdx+1}}. {{child.reviewedAt | date}}
              <div class="">
                <small class="text-warning">
                  ผู้ออกเอกสาร:
                  {{child.reviewedUser ? child.reviewedUser.name : '-'}}
                </small>
              </div>
              <div class="">
                <small class="text-success">
                  ผู้รับผิดชอบ: {{getDepartment(child.type)}}
                </small>
              </div>
            </td>
            <td class="text-center" style="white-space: nowrap;">
              {{child.code}}
              (
              <span class="text-uppercase" :class="{
                'text-danger': child.cparType === 'nc',
                'text-warning': child.cparType === 'o'
              }">
                {{child.cparType}}
              </span>
              )
              <div>
                <small class="text-info">
                  ข้อกำหนด: {{child.issueStandard}}
                </small>
              </div>
            </td>
            <td style="white-space: pre-line;">{{child.name}}</td>
            <td
              class="text-center"
              style="white-space: nowrap;">
              {{child.respondedAt | date}}

              <div class="">
                <small class="text-success">
                  ปิด CPAR: {{child.approvedAt | date}}
                </small>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  DROPDOWN_CHILDREN,
  CREATE_PAPER_LINK,
  DESTROY_PAPER_LINK
} from './graph'
import debounce from 'lodash/debounce'

export default {
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: [],
      search: '',
    }
  },
  methods: {
    getDepartment (department) {
      const dep = department.split("-")
      return dep[0].toUpperCase()
    },
    getList: debounce(function (filter) {
      this.$apollo.query({
        query: DROPDOWN_CHILDREN(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          q: filter
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.items = res.data.papers
      })
      .catch(() => {
        this.items = []
      })
    }, 150),
    createAll () {
      this.items.forEach(this.addPaper)
    },
    addPaper (paper) {
      this.$apollo.mutate({
        mutation: CREATE_PAPER_LINK(this.templateType),
        variables: {
          paperType: this.paperType,
          parentId: this.paperId,
          childId: paper.id
        }
      })
      .then(() => {
        this.emitUpdated()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    deletePaper (paperId) {
      this.$apollo.mutate({
        mutation: DESTROY_PAPER_LINK(this.templateType),
        variables: {
          paperType: this.paperType,
          parentId: this.paperId,
          childId: paperId
        }
      })
      .then(() => {
        this.emitUpdated()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  watch: {
    search (value) {
      if (value) {
        this.getList({
          limit: 20,
          params: {
            search: value
          },
          order: 'code'
        })
      } else {
        this.items = []
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
